
import { Paths } from '@/router/Paths';
import fetchAdapter from '@/toolkit/fetchAdapter';
import Button from '@/components/Button/Button.vue';
import { defineComponent } from 'vue';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Data {
  fullName: string;
  email: string;
  message: string;
  nameHasError: boolean;
  emailHasError: boolean;
  messageHasError: boolean;
  isMessageSending: boolean;
  sendAttempted: boolean;
  isSuccessfulSend: boolean;
  errorMessage: string;
  rerouteID?: number;
  rerouteInterval?: number;
  rerouteCountdown: number;
}

const COUNTDOWN = 3;

export default defineComponent({
  name: 'ContactForm',
  components: {
    Button
  },
  props: {
    isFullNameRequired: {
      type: Boolean,
      required: false,
      default: true
    },
    isEmailRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    contactType: {
      type: String,
      required: false,
      default: 'contact',
    },
    appName: {
      type: String,
      required: false,
    },
  },
  data(): Data {
    return {
      fullName: '',
      email: '',
      message: '',
      nameHasError: false,
      emailHasError: false,
      messageHasError: false,
      isMessageSending: false,
      sendAttempted: false,
      isSuccessfulSend: false,
      errorMessage: '',
      rerouteID: undefined,
      rerouteInterval: undefined,
      rerouteCountdown: COUNTDOWN,
    };
  },
  beforeUnmount() {
    if (this.rerouteID !== undefined) {
      clearTimeout(this.rerouteID);
    };
    if (this.rerouteInterval !== undefined) {
      clearInterval(this.rerouteInterval);
    }
  },
  methods: {
    checkForValidMessage() {
      const isFullNameValid = this.fullName.length >= 2 || !this.isFullNameRequired;
      const isEmailValid = emailRegex.test(this.email) || !this.isEmailRequired;
      const isMessageValid = this.message.length >= 5;
      this.nameHasError = !isFullNameValid;
      this.emailHasError = !isEmailValid;
      this.messageHasError = this.message.length === 0;
      return (isFullNameValid && isEmailValid && isMessageValid);
    },
    sendMessage() {
      if (this.isMessageSending) return;
      this.isSuccessfulSend = false;
      this.errorMessage = '';
      this.isMessageSending = true;
      if (this.checkForValidMessage()) {
        const data = {
          type: this.contactType,
          name: this.fullName,
          email: this.email,
          message: this.message,
          appName: this.appName,
        };
        fetchAdapter('/.netlify/functions/mailer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((): void => {
            this.isMessageSending = false;
            this.fullName = '';
            this.email = '';
            this.message = '';
            this.isSuccessfulSend = true;
            this.rerouteID = setTimeout(() => {
              this.$router.push(Paths.HOME);
            }, COUNTDOWN * 1000);
            this.rerouteInterval = setInterval(() => {
              this.rerouteCountdown -= 1;
            }, 1000);
          })
          .catch((): void => {
            this.errorMessage = 'Sorry, we were unable to send your message. Please try again.';
          });
      } else {
        this.isMessageSending = false;
      }
      this.sendAttempted = true;
    },
  },
});
